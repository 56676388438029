<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="params.userName" size='small' style="width:150px;" placeholder='请输入昵称'></a-input>
                <a-input v-model="params.coinName" size='small' style="width:150px;" placeholder='请输入大币种名称'></a-input>
                <a-input v-model="params.coinVersionName" size='small' style="width:150px;" placeholder='请输入版别名称'></a-input>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>

            </div>
        </div>

        
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                 <div slot="setup" slot-scope="row">
                     <a style='margin-left:5px' @click="subscribePushClick(row)">推送</a>
                </div>
            </a-table>
        </div>

        
        <Audio src=''></Audio>
    </div>
</template>

<script>
export default {
    data(){
        return {
            columns:[
                {title:'昵称', align:'center', dataIndex:'userName'},
                {title:'大币种',align:'center',dataIndex:'coinName'},
                {title:'版别', align:'center',dataIndex:'coinVersionName'},
                {title: '订阅时间',align:'center',dataIndex: 'createTime'},
                {title: '推送时间',align:'center',dataIndex: 'pushTime'},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            visible:false,
            params:{pageNum:1,pageSize:10,userName:''},
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            paginationModal:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            messages:[],

            modalParams:{},
            loadingModal:false,
        }
    },
    created(){
        this.getData(this.params)
    },
    methods:{
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/subscribe/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },

        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.limit = pageSize
            this.getData(this.params)
        },
        changePageModal(page){
            const {current} = page
            this.paginationModal.current = current
            this.modalParams.pageNum = current
            this.getTakeMessagesV2(this.modalParams)
        },
        cancelModal(){
            this.modalParams = {}
            this.messages = []
        },
        
        subscribePushClick(row){
            const {productNo} = row
            this.axios('/dq_admin/subscribe/push',{params:{id: row.id}}).then(res=>{
                const {status,data,message} = res
                if(status == '200'){
                    this.$message.success(message)
                }
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;}
</style>